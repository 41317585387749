<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-info shadow-success border-radius-lg py-3 d-flex justify-content-between">
              <h6 class="text-white text-capitalize ps-3 pt-1">
                Клиенты
              </h6>

              <router-link to="/consumers/consumer/new" class="rounded-circle p-1 mx-3 bg-white">
                <plus-icon size="30" fill-color="#49A3F1"/>
              </router-link>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Имя
                    <material-input
                        id="name"
                        name="name"
                        size="sm"
                        class="mt-2 w-75"
                        v-model="currentFilter.name"
                        value="{{ currentFilter.name }}"
                    />
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Заголовок
                    <material-input
                        id="title"
                        name="title"
                        size="sm"
                        class="mt-2 w-75"
                        v-model="currentFilter.title"
                        value="{{ currentFilter.title }}"
                    />
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    UID
                    <material-input
                        id="uid"
                        name="uid"
                        size="sm"
                        class="mt-2 w-75"
                        v-model="currentFilter.uid"
                        value="{{ currentFilter.uid }}"
                    />
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Публичный ID
                    <material-input
                        id="public_id"
                        name="public_id"
                        size="sm"
                        class="mt-2 w-75"
                        v-model="currentFilter.publicId"
                        value="{{ currentFilter.publicId }}"
                    />
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Телефон
                    <material-input
                        id="phone"
                        name="phone"
                        size="sm"
                        class="mt-2 w-75"
                        v-model="currentFilter.phone"
                        value="{{ currentFilter.phone }}"
                    />
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Дата создания
                  </th>
                  <th class="text-secondary opacity-7"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="consumer in consumers" :key="consumer.id">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">
                          {{ consumer.name }}
                        </h6>
                        <p class="text-xs text-secondary mb-0">
                          {{ consumer.email }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ consumer.title || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ consumer.uid || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ consumer.publicId || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{
                        consumer.phone === ""
                            ? "None"
                            : consumer.phone
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ formatDate(consumer.created) }}
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <div
                        style="cursor: pointer"
                        class="text-secondary font-weight-bold text-sm"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                        @click="
                            this.$router.push({
                                path: `${
                                    '/consumers/consumer/' +
                                    consumer.id
                                }`,
                            })
                        "
                    >
                      <account-edit-icon size="30"/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <material-pagination color="info" size="lg">
        <material-pagination-item
            prev
            :disabled="currentPage === 1"
            :class="currentPage === 1 ? 'opacity-5' : ''"
            @click="currentPage > 1 ? loadConsumers(currentPage - 1) : false"
        />
<!--        <material-pagination-item-->
<!--            v-for="pageNum in 50"-->
<!--            :key="pageNum"-->
<!--            :label="pageNum"-->
<!--            :active="pageNum === currentPage"-->
<!--            @click="currentPage !== pageNum ? loadConsumers(pageNum) : false"-->
<!--        />-->

        <template v-for="page in paginationRange" :key="page">
          <material-pagination-item
              v-if="page !== '...'"
              :label="page"
              :active="page === currentPage"
              @click="currentPage !== page ? loadConsumers(page) : false"
          />
          <span v-else class="text-secondary mx-2">{{ page }}</span>
        </template>
        <material-pagination-item
            next
            :disabled="currentPage === countPages"
            :class="currentPage === countPages ? 'opacity-5' : ''"
            @click="currentPage < countPages ? loadConsumers(currentPage + 1) : false"
        />
      </material-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import index from "vuex";
import {mask} from "vue-the-mask";

import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialPaginationItem from "@/components/MaterialPaginationItem.vue";
import MaterialInput from "@/components/MaterialInput.vue";

import PlusIcon from "vue-material-design-icons/Plus.vue";
import AccountEditIcon from "vue-material-design-icons/AccountEdit.vue";

import {debounce} from "../helpers/debounce.js";

import {API_HOST, CONSUMER_COUNT_LIST} from "@/config";

export default {
  name: "tables",
  computed: {
    index() {
      return index;
    },
    paginationRange() {
      return this.generatePaginationRange();
    },
  },
  data() {
    return {
      consumers: [],
      countPages: 1,
      currentPage: 1,
      currentFilter: {
        name: this.$route.query.name,
        publicId: this.$route.query.publicId,
        title: this.$route.query.title,
        uid: this.$route.query.uid,
        phone: this.$route.query.phone,
      },
    };
  },
  directives: {mask},
  mounted() {
    this.loadConsumers(this.currentPage, this.currentFilter);
    this.$store.commit("setBreadcrumbs", ["Клиенты"]);
  },
  beforeUnmount() {
    this.$store.commit("setBreadcrumbs", []);
  },
  components: {
    MaterialInput,
    MaterialPagination,
    MaterialPaginationItem,
    PlusIcon,
    AccountEditIcon,
  },
  methods: {
    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      };
      return new Date(dateString).toLocaleDateString('ru-RU', options);
    },
    loadConsumers(page, filter) {
      this.currentPage = page;

      axios
          .get(API_HOST + "/v1/client/consumers", {
            params: {
              page: page,
              size: CONSUMER_COUNT_LIST,
              filter: filter,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + this.$store.getters.getTokenPair.access,
            },
          })
          .then((response) => {
            this.consumers = response.data.list;
            this.countPages = response.data.pages;

            console.log(response.data);
          })
          .catch((error) => {
            console.warn(error);
          });
    },
    setRouteQuery(property) {
      let query = {...this.$route.query};
      if (this.currentFilter[property]) {
        this.$router.push({
          query: {
            ...query,
            [property]: this.currentFilter[property],
          },
        });
      } else {
        delete query[property];
        this.$router.push({
          query: {
            ...query,
          },
        });
      }
    },
    generatePaginationRange() {
      const total = this.countPages;
      const current = this.currentPage;
      const delta = 2;

      const range = [];
      for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
        range.push(i);
      }
      if (current - delta > 2) range.unshift("...");
      if (current + delta < total - 1) range.push("...");
      range.unshift(1);
      if (total > 1) range.push(total);
      return range;
    },
  },
  watch: {
    "$route.query": {
      handler: function () {
        this.loadConsumers(this.currentPage, this.currentFilter);
      },
      deep: true,
    },
    "currentFilter.name": debounce(function () {
      this.setRouteQuery("name");
    }, 500),
    "currentFilter.title": debounce(function () {
      this.setRouteQuery("title");
    }, 500),
    "currentFilter.uid": debounce(function () {
      this.setRouteQuery("uid");
    }, 500),
    "currentFilter.publicId": debounce(function () {
      this.setRouteQuery("publicId");
    }, 500),
    "currentFilter.phone": debounce(function () {
      this.setRouteQuery("phone");
    }, 500),
  },
};
</script>
